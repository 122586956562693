import { Component, forwardRef, Input } from '@angular/core';
import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
  selector: 'app-toggle',
  styleUrls: ['./toggle.component.scss'],
  templateUrl: './toggle.component.html',
  standalone: true,
  imports: [TooltipDirective],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() checked = false;
  @Input() label = '';
  @Input() description = '';
  @Input() tooltip = '';
  @Input() required = false;

  onChange = (checked: boolean): void => {};
  onTouched = (): void => {};

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
